import React, {useEffect, useState} from "react";
import Footer from "@/components/Footer";
import LoginForm from "./form";
import Logo from "@/assets/logo.svg";

import styles from "./style/index.module.less";
import request, {Res} from "@/utils/request";
import {Image, Message, Modal} from "@arco-design/web-react";
import history from "@/history";

export default () => {

  const [qrcodeUrl, setQrcodeUrl] = useState("")
  const [timer, setTimer] = useState(null)

  const getLoginQrcode = () => {
    Message.loading("加载中...")
    request.get("/server_admin/qrcodeLogin").then((res: Res) => {
      if (res.code == 0) {
        setQrcodeUrl(res.data.url);
        setTimer(setInterval(() => checkScanQrcode(res.data.login_ticket), 2000));
      }
      Message.clear();
    })
  }

  const checkScanQrcode = (ticket: string) => {
    request.post("/server_admin/qrcodeLoginCheck", {ticket}).then((res: Res) => {
      if (res.code == 0) {
        clearInterval(timer);
        //登录成功
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        window.location.href = history.createHref({
          pathname: '/statistics',
        });
      } else if (res.code == -1) {
        //二维码已失效
        clearInterval(timer);
        Modal.error({content: res.msg})
      } else if (res.code == -2) {
        //未扫码
      } else if (res.code == -3) {
        //无权限
        Message.error(res.msg);
        clearInterval(timer);
        window.close()
      }
    })
  }

  useEffect(() => {
    document.body.setAttribute('arco-theme', 'dark');
    getLoginQrcode();
    return () => {
      clearInterval(timer);
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Logo />
        <div className={styles['logo-text']}>订个包间</div>
      </div>
      <div className={styles.banner}>
        <div className={styles.qrcodeBox}>
          <Image src={qrcodeUrl} width={200} height={200}/>
        </div>
        <div className={styles.qrcodeTips}>微信扫码登录</div>
      </div>
      <div className={styles.content}>
        <div className={styles['content-inner']}>
          <LoginForm />
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};
