import React, {useEffect, useState} from "react";
import {createStore} from "redux";
import {Provider} from "react-redux";
import {ConfigProvider} from "@arco-design/web-react";
import zhCN from "@arco-design/web-react/es/locale/zh-CN";
import enUS from "@arco-design/web-react/es/locale/en-US";
import ReactDOM from "react-dom";
import {Route, Router, Switch} from "react-router-dom";
import rootReducer from "./redux";
import history from "./history";
import PageLayout from "./layout/page-layout";
import Setting from "./components/Settings";
import {GlobalContext} from "./context";
import "./style/index.less";
import Login from "./pages/login";
import checkLogin from "./utils/checkLogin";
import {api} from "./redux/api";

const store = createStore(rootReducer);

function Index() {
  const localeName = localStorage.getItem('arco-lang') || 'zh-CN';

  if (!localStorage.getItem('arco-lang')) {
    localStorage.setItem('arco-lang', localeName);
  }

  const [locale, setLocale] = useState();

  async function fetchLocale(ln?: string) {
    const locale = (await import(`./locale/${ln || localeName}`)).default;
    setLocale(locale);
  }

  function getArcoLocale() {
    switch (localeName) {
      case 'zh-CN':
        return zhCN;
      case 'en-US':
        return enUS;
      default:
        return zhCN;
    }
  }

  function fetchUserInfo(token = "") {
    token && api.checkLogin().then(res => {
      // @ts-ignore
      if (res.code === 0) {
      } else {
        localStorage.removeItem("token")
        history.push("/login");
      }
    })
  }

  useEffect(() => {
    fetchLocale();
  }, []);

  useEffect(() => {
    let token = checkLogin();
    if (token) {
      fetchUserInfo(token);
    } else {
      history.push("/login");
    }
  }, []);

  const contextValue = {
    locale,
  };
  return locale ? (
    <Router history={history}>
      <ConfigProvider locale={getArcoLocale()}>
        <Provider store={store}>
          <GlobalContext.Provider value={contextValue}>
            <Switch>
              <Route path="/login" component={Login}/>
              <Route path="/"  component={PageLayout}/>
            </Switch>
            <Setting/>
          </GlobalContext.Provider>
        </Provider>
      </ConfigProvider>
    </Router>
  ) : null;
}

ReactDOM.render(<Index/>, document.getElementById('root'));
